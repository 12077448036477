var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"facility-item",class:{ borderless: _vm.itemForm.fields }},[(_vm.itemForm.selectable)?_c('div',{staticClass:"checkbox-item"},[_c('FormCheckbox',{staticClass:"item-checkbox",attrs:{"id":("check-" + (_vm.itemForm.label)),"label":_vm.$t((_vm.locales + "." + (_vm.itemForm.label) + ".title")),"popover":_vm.$te((_vm.locales + "." + (_vm.itemForm.label) + ".description"))
          ? _vm.$t((_vm.locales + "." + (_vm.itemForm.label) + ".description"))
          : '',"icon-classes":[
        ("fa-solid fa-" + (_vm.$t((_vm.locales + "." + (_vm.itemForm.label) + ".icon"))) + " fa-xs")
      ]},on:{"input":function($event){return _vm.$emit('onInput', _vm.itemForm.selected, _vm.itemForm.id)}},model:{value:(_vm.itemForm.selected),callback:function ($$v) {_vm.$set(_vm.itemForm, "selected", $$v)},expression:"itemForm.selected"}}),_c('transition',{attrs:{"name":"toggle-appear"}},[(_vm.demandToggle && _vm.itemForm.selected)?_c('Toggle',{class:['facility-toggle', { default: !_vm.itemForm.allowOnDemand }],model:{value:(_vm.itemForm.onDemand),callback:function ($$v) {_vm.$set(_vm.itemForm, "onDemand", $$v)},expression:"itemForm.onDemand"}},[_c('Option',{attrs:{"label":_vm.$t(
              'components.space_configurations_selector.default_switcher.default'
            ),"value":false}}),(_vm.itemForm.allowOnDemand)?_c('Option',{attrs:{"label":_vm.$t(
              'components.space_configurations_selector.default_switcher.on_request'
            ),"value":true}}):_vm._e()],1):_vm._e()],1)],1):_vm._e(),(!_vm.itemForm.selectable)?_c('div',{staticClass:"non-selectable"},[_c('div',{staticClass:"icon"},[_c('i',{class:[("fa-solid fa-" + (_vm.itemForm.icon) + " fa-xs")]})]),_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.itemForm.label)+" ")])]):_vm._e(),(_vm.itemForm.fields)?_c('div',{staticClass:"items-form"},_vm._l((_vm.itemForm.fields),function(field,index){return _c('WMInput',{key:index,attrs:{"placeholder":field.placeholder,"inline":true,"required":field.required},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})}),1):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }